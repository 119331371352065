import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { BadgeModule } from 'primeng/badge';
import { Toast, ToastModule } from 'primeng/toast';
import { MessageService as ngMessageService } from 'primeng/api';

import { IMessage, MessageDuration, MessageLevel, MessageType } from '@lms-common/core/models/message.model';
import { MessageService } from '@lms-common/core/services/message.service';
import { HttpNotificationService } from '@lms-common/core/services/httpnotifications.service';
import { CommonComponentsModule } from '@lms-commoncomponents/commoncomponents.module';
import { AppState } from '@lms-common/redux/app.state';
import { authSelectors } from '@lms-common/redux/auth/auth.selector';

@Component({
    selector: 'app-toasts',
    styleUrls: ['./toast.component.scss'],
    template: `
        <p-toast [styleClass]="isImpersonating ? 'custom-impersonating-toast' : 'custom-toast'">
            <ng-template let-message pTemplate="message">
                <i class="close-icon" [ngClass]="{ 'ph-light ph-x': message.sticky, 'ph-fill ph-hourglass-medium': !message.sticky }"></i>
                <section (click)="onToastClick(message)" class="d-flex align-items-center" style="flex: 1">
                    <i
                        class="p-toast-message-icon ph-thin"
                        [ngClass]="{
                            'p-toast-message-download': message.data && message.data.message.hasAttachment,
                            'p-toast-message-task': message.data && message.data.message.isTask
                        }"
                    ></i>
                    <section class="p-toast-message-text">
                        <section class="p-toast-summary" *ngIf="message.summary">{{ message.summary }}</section>
                        <section class="p-toast-detail" *ngIf="message.detail">{{ message.detail }}</section>
                    </section>
                </section>
            </ng-template>
        </p-toast>
    `,
    standalone: true,
    imports: [CommonComponentsModule, CommonModule, TranslateModule, ToastModule, BadgeModule, RouterModule],
    providers: [HttpNotificationService, ngMessageService],
    encapsulation: ViewEncapsulation.None,
})
export class ToastComponent implements OnInit, OnDestroy {
    @ViewChild(Toast) toast: Toast;
    @ViewChild(Toast, { read: ViewContainerRef, static: true }) toastRef: ViewContainerRef;

    isImpersonating = false;
    private destroy$ = new Subject<void>();

    constructor(
        private state: Store<AppState>,
        @Inject(MessageService) private messageService: MessageService,
        private router: Router,
        private httpNotificationService: HttpNotificationService,
        private ngMessageService: ngMessageService
    ) {}

    ngOnInit() {
        this.state
            .select(authSelectors.isImpersonating)
            .pipe(
                takeUntil(this.destroy$),
                tap((isImpersonating: boolean) => (this.isImpersonating = isImpersonating))
            )
            .subscribe();

        this.messageService.itemQueued
            .pipe(
                takeUntil(this.destroy$),
                filter((item) => (item.messageType === MessageType.Toast || item.messageType === MessageType.ToastNotification) && !item.isDismissed),
                tap((item) => this.addMessage(item))
            )
            .subscribe();

        // todo: subscribe to deletequeue on messageservice, keep track of toastrs? set dismiss message on object?
        this.messageService.resetQueue
            .pipe(
                takeUntil(this.destroy$),
                tap(() => this.ngMessageService.clear())
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onToastClick(message: any) {
        const item = message.data.message;

        if (item && item.dismissable) {
            this.dismissMessage(message, item);
        }
        if (item && item.hasAttachment) {
            this.httpNotificationService.downloadAttachment(item);
        }
        if (item.isTask) {
            this.router.navigate(['/', 'admin', 'users', 'matching', item.id]);
        }
    }

    private clearToast(id?: number) {
        if (!id) {
            return;
        }

        this.toast.messages = this.toast.messages.filter((x) => x.id !== id);
        const changeDetectorRef = this.toastRef.injector.get(ChangeDetectorRef);
        changeDetectorRef.detectChanges();
    }

    private addMessage(item: IMessage) {
        if (!item) {
            return;
        }
        const severity = this.getSeverityFromLevel(item.messageLevel);

        const message = {
            id: item.id,
            severity: severity,
            summary: !String.isNullOrWhiteSpace(item.content) ? item.title : undefined,
            detail: String.isNullOrWhiteSpace(item.content) ? item.title : item.content,
            sticky: item.messageDuration === MessageDuration.Fixed || item.dismissable,
            life: <number>item.messageDuration,
            data: { message: item },
            closable: false,
        };

        this.ngMessageService.add(message);
    }

    private getSeverityFromLevel(messageLevel: MessageLevel) {
        switch (messageLevel) {
            case MessageLevel.Success:
                return 'success';
            default:
            case MessageLevel.Info:
                return 'info';
            case MessageLevel.Warn:
                return 'warn';
            case MessageLevel.Danger:
                return 'error';
        }
    }

    private dismissMessage(message: any, item: any) {
        if (this.isImpersonating) {
            this.clearToast(message.id);
            return;
        }

        if (item.beforeDismiss) {
            item.beforeDismiss().then((canDismiss) => {
                if (canDismiss) {
                    this.clearToast(message.id);
                    if (item.onDismiss) {
                        item.onDismiss();
                    }
                }
            });
        } else {
            this.clearToast(message.id);
            if (item.onDismiss) {
                item.onDismiss();
            }
        }
    }
}
