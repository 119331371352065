import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ColDef, ColGroupDef, DomLayoutType, GridApi } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { Observable, Subject, takeUntil } from 'rxjs';

import { ColumnDefinition } from '@lms-common/core/grid/columndefinition.model';
import { defaultColumnDef } from './configs/default-coldef.config';
import { GridTemplateComponent } from './cellComponents/gridtemplate.component';
import { GridEditorTemplateComponent } from './cellComponents/grideditortemplate.component';
import { CustomCellRendererComponent } from './cellComponents/custom-cell-renderer.component';

@Component({
    selector: 'app-data-grid',
    styleUrls: ['./datagrid.component.scss'],
    templateUrl: './datagrid.component.html',
    standalone: true,
    imports: [CommonModule, TranslateModule, AgGridAngular, PaginationModule, FormsModule],
    providers: [],
    encapsulation: ViewEncapsulation.None,
})
export class DataGridComponent implements OnInit, OnDestroy {
    @Input() rowData: Array<any>;
    @Input() customColumnDefs: ColumnDefinition[];
    @Input() columnDefs: (ColDef | ColGroupDef)[];
    @Input() totalItems: number;
    @Input() currentPage: number;
    @Input() itemsPerPage: number;
    @Input() maxNavLinks = 5;
    @Input() enablePaging = true;
    @Input() enableFirstAndLastPagination = true;
    @Input() public onExportPressed: Observable<void>;
    @Input() exportFileName: string;

    @Output() sortChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() page: EventEmitter<any> = new EventEmitter<any>();
    @Output() rowLostFocus: EventEmitter<any> = new EventEmitter<any>();

    private gridApi!: GridApi;
    gridId: string;

    public defaultColDef: ColDef = defaultColumnDef;
    public domLayout: DomLayoutType = 'autoHeight';
    public popupParent: HTMLElement | null = document.body;
    public themeClass = 'ag-theme-material';
    private destroy$ = new Subject<void>();

    public get agColumnDefinition(): ColDef[] {
        return this.customColumnDefs && this.customColumnDefs.length > 0
            ? this.customColumnDefs.map((colDef) => this.convertToColDef(colDef))
            : this.columnDefs;
    }

    public components: {
        [p: string]: any;
    } = {
        customCellRenderer: CustomCellRendererComponent,
        TemplateRenderer: GridTemplateComponent,
        EditorTemplateRenderer: GridEditorTemplateComponent,
    };

    constructor(private translateService: TranslateService) {}

    ngOnInit() {
        this.gridId = `grid${Math.random().toString(36).substr(2, 8)}`;

        this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe((_) => this.gridApi.refreshHeader());

        if (this.onExportPressed) {
            this.onExportPressed.pipe(takeUntil(this.destroy$)).subscribe((_) => this.gridApi.exportDataAsCsv(this.getParams()));
        }
    }
    ngOnDestroy(): void {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    pageChanged(event: PageChangedEvent): void {
        this.page.emit({ offset: event.page - 1 });
    }

    onGridReady(params: any) {
        this.gridApi = params.api;
    }

    headerHeightSetter() {
        const padding = 20;
        const height = this.headerHeightGetter() + padding;
        this.gridApi.setGridOption('headerHeight', height);
    }

    onSortChanged(event) {
        const sortedColumn = event.columnApi.getColumnState().find((col) => Boolean(col.sort));
        if (sortedColumn) {
            const sortEvent = { sorts: [{ prop: sortedColumn.colId }], newValue: sortedColumn.sort, prevValue: '', offset: undefined };
            this.sortChanged.emit(sortEvent);
        }
    }

    private convertToColDef(col: ColumnDefinition): ColDef {
        return {
            field: col.field,
            sortable: col.sortable,
            suppressMovable: !col.dragable,
            resizable: col.resizable === true,
            pinned: col.pinned,
            wrapText: col.wrapText,
            hide: col.hide,
            autoHeight: col.wrapText,
            editable: col.editable,
            cellClass: col.cellClass,
            flex: col.flex,
            width: col.width,
            initialWidth: col.initialWidth,
            minWidth: col.minWidth,
            maxWidth: col.maxWidth,
            cellEditor: col.cellEditorTemplate ? 'EditorTemplateRenderer' : col.cellEditorRenderer,
            cellEditorParams: col.cellEditorTemplate ? { ngTemplate: col.cellEditorTemplate } : undefined,
            cellRenderer: col.cellTemplate ? 'TemplateRenderer' : col.cellRenderer ?? undefined,
            cellRendererParams: col.cellTemplate ? { ngTemplate: col.cellTemplate } : undefined,
            headerComponent: col.headerTemplate ? 'TemplateRenderer' : undefined,
            headerComponentParams: col.headerTemplate ? { ngTemplate: col.headerTemplate } : undefined,
            headerValueGetter: !col.headerTemplate && col.translateHeader ? () => this.translateService.instant(col.header) : undefined,
            headerName: !col.headerTemplate && !col.translateHeader ? col.header : undefined,
        };
    }

    private headerHeightGetter() {
        const columnHeaderTexts = document.querySelectorAll(`#${this.gridId} .ag-header-cell-text`);
        const columnHeaderTextsArray = [];

        columnHeaderTexts.forEach((node) => columnHeaderTextsArray.push(node));

        const clientHeights = columnHeaderTextsArray.map((headerText) => headerText.clientHeight);
        const tallestHeaderTextHeight = Math.max(...clientHeights);
        return tallestHeaderTextHeight;
    }

    private getParams() {
        return {
            fileName: this.exportFileName,
        };
    }
}
