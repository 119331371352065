import { defaultShortTranslation, defaultTranslatedTranslation, defaultTranslation, Translation } from '../models/translation.model';

export const Languages: Translation[] = [
    defaultTranslation,
    {
        code: 'nl-BE',
        displayName: 'Nederlands',
    },
    {
        code: 'fr-BE',
        displayName: 'Fran�ais',
    },
];

export const TranslatedLanguages: Translation[] = [
    defaultTranslatedTranslation,
    {
        code: 'nl-BE',
        displayName: 'common.culture.nl-be',
    },
    {
        code: 'fr-BE',
        displayName: 'common.culture.fr-be',
    },
];

export const ShortLanguages: Translation[] = [
    defaultShortTranslation,
    {
        code: 'nl-BE',
        displayName: 'NL',
    },
    {
        code: 'fr-BE',
        displayName: 'FR',
    },
];
