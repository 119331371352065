import 'reflect-metadata';

import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateParser } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BlobModule } from 'angular-azure-blob-service';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { FileUploadModule } from 'ng2-file-upload';
import { SignalRModule } from 'ng2-signalr';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { API_BASE_URL } from 'generated/lmsapi.generated';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { appRouting } from './app.routing';
import { AppCoreModule } from './common/core/appcore.module';
import { AppinsightModule } from './common/core/appinsight.module';
import { AuthenticatedGuard } from './common/core/auth/authenticated.guard';
import { AuthorizationService } from './common/core/auth/authorization.service';
import { createStorageFactory } from './common/core/auth/authstorage.service';
import { CurrentUserService } from './common/core/auth/currentuser.service';
import { HttpAuthService } from './common/core/auth/httpauth.service';
import { ImpersonationService } from './common/core/auth/impersonation.service';
import { HasPermissionGuard } from './common/core/auth/permission.guard';
import { TemplateWrapperComponent } from './common/core/components/template-wrapper.component';
import { DialogService } from './common/core/dialog/dialog.service';
import { DialogContainerService } from './common/core/dialog/dialogcontainer.service';
import { HttpService } from './common/core/http/http.service';
import { InterceptorsModule } from './common/core/http/interceptors/interceptors.module';
import { MappingHttpService } from './common/core/http/mappinghttp.service';
import { CreateFileUploaderService } from './common/core/services/createfileuploader.service';
import { FilterService } from './common/core/services/filter.service';
import { HttpTranslateLoader } from './common/core/services/httptranslate.loader';
import { MessageService } from './common/core/services/message.service';
import { MissingTranslationWarningHandler } from './common/core/services/missingtranslationwarning.handler';
import { TranslationManager } from './common/core/services/translation.manager';
import { DataLayerModule } from './common/datalayer/datalayer.module';
import { NavigationEffects } from './common/layout/navigation.effects';
import { TranslationEffects } from './common/layout/translation.effects';
import { metaReducers, reducers } from './common/redux/app.reducer';
import { AuthEffect } from './common/redux/auth/auth.effects';
import { ImpersonationEffects } from './common/redux/auth/impersonation.effects';
import { NotificationEffect } from './common/redux/notification.effects';
import { AppRouterStateSerializer } from './common/routing/approuterstate.serializer';
import { AuthContainerComponent } from './components/auth/authcontainer.component';
import { CallbackComponent } from './components/auth/callback.component';
import { CommonComponentsModule } from './components/common/commoncomponents.module';
import { ToastComponent } from './components/common/notifications/toast.component';
import { ConfirmDialogComponent } from './components/dialogs/confirm/confirm.dialog';
import { FaqPageDialogComponent } from './components/faq/dialogs/faq-page-dialog/faq-page-dialog.component';
import { LayoutModule } from './components/layout/layout.module';
import { ToggleableTranslateParser } from './components/layout/services/toggleabletranslateparser.service';
import { CustOAuthModule } from './custoauth.module';
import { createSignalRConfig } from './signalr.config';
import { TenantConfigurationFactory } from './tenantconfiguration.factory';
import { DialogContainerComponent } from './components/layout/dialog/dialogcontainer.component';
import { ForbiddenComponent } from './components/auth/forbidden.component';
import { LoginComponent } from './components/auth/login.component';
import { LogoutComponent } from './components/auth/logout.component';
import { ActiveRoleService } from './common/core/services/activerole.service';
import { ActiveRoleEffects } from './common/layout/activerole.effects';

export function getBaseUrl(): string {
    return environment.multitenancy[window.location.host].poapiroot;
}

@NgModule({
    imports: [
        BlobModule.forRoot(),
        AppCoreModule,
        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        FileUploadModule,
        AppinsightModule.forRoot(),
        CustOAuthModule.forRoot(),
        BrowserModule,
        SignalRModule.forRoot(createSignalRConfig),
        NgxDatatableModule,
        RouterModule.forRoot(appRouting, { enableTracing: false, onSameUrlNavigation: 'reload' }),
        StoreModule.forRoot(reducers, { metaReducers }),
        StoreRouterConnectingModule,
        DataLayerModule,
        TranslateModule.forRoot({
            missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MissingTranslationWarningHandler },
            parser: { provide: TranslateParser, useClass: ToggleableTranslateParser },
            loader: {
                provide: TranslateLoader,
                useClass: HttpTranslateLoader,
                deps: [HttpClient, TenantConfigurationFactory],
            },
        }),
        /**
         * Store devtools instrument the store retaining past versions of state
         * and recalculating new states. This enables powerful time-travel
         * debugging.
         *
         * To use the debugger, install the Redux Devtools extension for either
         * Chrome or Firefox
         *
         * See: https://github.com/zalmoxisus/redux-devtools-extension
         */
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        EffectsModule.forRoot([NavigationEffects, TranslationEffects, ActiveRoleEffects, AuthEffect, ImpersonationEffects, NotificationEffect]),
        LayoutModule,

        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        ToastContainerModule,
        InterceptorsModule,
        CommonComponentsModule,
        DialogContainerComponent,
        ProgressSpinnerModule,
        ToastComponent,
    ],
    providers: [
        { provide: API_BASE_URL, useFactory: getBaseUrl },
        { provide: RouterStateSerializer, useClass: AppRouterStateSerializer },
        { provide: OAuthStorage, useFactory: createStorageFactory },
        HttpService,
        MappingHttpService,
        DialogContainerService,
        CurrentUserService,
        HttpAuthService,
        AuthorizationService,
        AuthenticatedGuard,
        CreateFileUploaderService,
        ImpersonationService,
        HasPermissionGuard,
        TranslationManager,
        MessageService,
        DialogService,
        FilterService,
        ActiveRoleService,
    ],
    declarations: [
        AppComponent,
        LoginComponent,
        LogoutComponent,
        ForbiddenComponent,
        AuthContainerComponent,
        CallbackComponent,
        TemplateWrapperComponent,
        FaqPageDialogComponent,
    ],
    entryComponents: [ConfirmDialogComponent, TemplateWrapperComponent, FaqPageDialogComponent],
    bootstrap: [AppComponent],
})
export class AppModule {}
